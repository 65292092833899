import missionDummy from '../../dummy/mission/mission.json'

const mission = {
    getMission: async () => {
        const response = missionDummy;

        return response
    }
}

export default mission