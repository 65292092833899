import milestoneDummy from '../../dummy/milestone/milestone.json'

const milestone = {
    getMilestone: async () => {
        const response = milestoneDummy;

        return response
    }
}

export default milestone