import privacyDummy from '../../dummy/privacy/privacy.json'

const privacy = {
    getPrivacy: async () => {
        const response = privacyDummy;

        return response
    }
}

export default privacy